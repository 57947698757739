
.input_box {
    width: 100%;
    display: flex;

    .left_image {
        img {
            width: 40px;
            height: 40px;

        }

        .little_image {
            width: 32px;
            height: 32px !important;
        }

        padding-right: 10px;
    }

    .right_comment {
        flex: 1;
        width: 0;
        flex-shrink: 0;
        position: relative;

        .placeholder {
            width: 500px;
            position: absolute;
            height: 36px;
            top: 7px;
            left: 13px;
            color: #25263259;

            &:hover {
                display: none;
            }
        }

        .button_box {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            color: #25263259;

            i {
                font-size: 20px;
                color: #25263259;
            }

            .send_btn {
                height: 24px;
                padding: 6px 12px;
                font-size: 12px;
                background-color: #f2f2f4;
                cursor: not-allowed;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;

            }

            .isSend_btn {
                background-color: #fe2c55;
                color: #fff;
                cursor: pointer;
            }

            .cancel_btn {
                height: 24px;
                padding: 6px 12px;
                font-size: 12px;
                background-color: #f2f2f4;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;

                &:hover {
                    background-color: #e4e4e6;
                }
            }

            .send_btn_disable {
                cursor: not-allowed;
            }
        }
    }
}

// ::v-deep .popper__arrow{
//     display: none !important;
// }
.emoji_box {
    display: flex;
    flex-wrap: wrap;
    max-width: 472px;
    max-height: 254px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 9px 6px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .image_box {
        width: 32px;
        height: 32px;
        margin: 10px;

        img {
            width: 100%;
            height: 100%;
        }

    }

}
