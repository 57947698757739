
.comment_box {

    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    background-color: #fff;

    .comment_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            padding: 0;
            margin: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: #161823;
        }

        .selectWork {
            width: 80px;
            height: 32px;
            border: none;
            border-radius: 4px;
            background-color: #fe2c55;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: bold;
        }

    }

    .has_work {

        .show_work {

            margin-top: 20px;
            width: 100%;
            height: 160px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;

            background-color: #16182305;
            border-radius: 4px;

            .image_box {
                width: 60px;
                height: 80px;
                border-radius: 2;
                position: relative;
                cursor: pointer;

                .image {
                    width: 100%;
                    height: 100%;
                    border-radius: 2;
                    object-fit: cover;
                }

                .playIcon {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                }

            }

            .work_desc {
                flex: 1;
                width: 0;
                height: 120px;
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                box-sizing: border-box;

                // justify-content: space-between;
                .video_desc {

                    width: 100%;
                    height: 100px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 26px;
                    color: #161823;

                    .desc {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.5;
                        max-height: 3em;
                    }


                }

                .publish_date {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    color: #333;
                    mix-blend-mode: normal;
                    opacity: .4;
                    padding: 0;
                    margin: 0;
                }
            }

        }

        .comment_main {
            width: 100%;
            padding-bottom: 20px;

            .comment_header {
                width: 100%;
                height: 61px;
                line-height: 61px;
                border-bottom: 1px solid #f2f2f4;
                font-weight: 600;
                color: #1c1f23e6;
                font-size: 18px;

            }

            .comment_input {
                width: 100%;
                display: flex;
                padding: 24px 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #f2f2f4;
            }

            .content_info_box {
                width: 100%;

                .select_box {
                    width: 100%;
                    padding: 20px 0;
                    box-sizing: border-box;
                    padding-bottom: 40px;

                    ::v-deep .el-input__inner {
                        width: 165px;
                        background-color: #f2f2f4;
                        color: #252632;
                        border: none;
                        padding: 0px 10px 0px 16px;
                        height: 30px;
                        box-sizing: border-box;
                    }

                    ::v-deep .el-select .el-input .el-select__caret {
                        color: #252632;
                    }

                    ::v-deep .el-input__icon {
                        line-height: 30px;
                    }

                }

                .content_list {
                    .content_item {
                        width: 100%;
                        padding: 24px 0 17px 20px;
                        box-sizing: border-box;
                        display: flex;

                        .item_left {
                            width: 40px;

                            .image_box {
                                width: 40px;
                                height: 40px;
                                border-radius: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 100%;
                                }
                            }


                        }

                        .item_right {
                            flex: 1;
                            width: 0;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: column;
                            margin-left: 10px;

                            .content_header {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                .content_name {
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                    line-height: 20px;

                                    h4 {
                                        padding: 0;
                                        margin: 0;
                                    }

                                    .icon_au {
                                        background: #fe2c55;
                                        color: white;
                                        // min-width: 34px;
                                        height: 17px;
                                        line-height: 17px;
                                        padding: 0px 8px;
                                        border-radius: 2px;
                                        margin-left: 5px;
                                        font-size: 12px;
                                        text-align: center;
                                    }
                                }

                                .content_time {
                                    font-size: 12px;
                                    margin: 0 0 3px;
                                    line-height: 20px;
                                    color: #1c1f2399;
                                }



                            }

                            .content_info {
                                .digg_box {
                                    // margin-top: 10px;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    color: rgba(28, 31, 35, 0.6);

                                    .block {
                                        width: 60px;
                                        display: flex;
                                        align-items: center;

                                        // margin-right: 20px;
                                        .number {
                                            margin-left: 5px;
                                        }
                                    }
                                }

                                .input_container {
                                    width: 100%;
                                    padding: 10px 0;
                                    box-sizing: border-box;
                                }

                                .tip_option {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    color: rgba(28, 31, 35, 0.6);
                                    margin-top: 8px;
                                    cursor: pointer;
                                    font-size: 12px;
                                    padding: 10px 0;
                                    box-sizing: border-box;

                                    .line {
                                        width: 32px;
                                        height: 1px;
                                        display: inline-block;
                                        margin-right: 4px;
                                        background: rgba(22, 24, 35, 0.06);
                                    }
                                }
                            }

                        }


                    }

                }

            }

        }
    }

    .no_work {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }



}

.drawer {
    padding: 20px;
    box-sizing: border-box;

    .header {
        padding: 0px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #33333380;
        padding-bottom: 20px;

        .header_title {
            font-size: 20px;
            color: #161823;
        }
    }

    .active_show_item_work {
        background-color: #16182305;
    }

    .show_item_work {

        width: 100%;

        padding: 14px 16px;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;
        // border: 1px solid #666;

        border-radius: 4px;

        .image_box {
            width: 60px;
            height: 80px;
            border-radius: 2px;


            .image {
                width: 100%;
                height: 100%;
                border-radius: 2;
                // overflow-clip-margin: content-box;
                // overflow: clip;
                object-fit: cover;

            }

        }

        .work_desc {
            flex: 1;
            width: 0;
            height: 80px;
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            justify-content: space-between;

            p {
                padding: 0;
                margin: 0;
            }

            .video_desc {
                width: 100%;
                // height: 60px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;
                color: #161823;
                padding-right: 10px;
                box-sizing: border-box;

                .desc {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.5;
                    max-height: 3em;
                }
            }

            .publish_date {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: #333;
                mix-blend-mode: normal;
                opacity: .4;
            }
        }

        .comment_number {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #1c1f2399;
            font-size: 12px;
        }


    }

    .more_work {
        width: 100%;
        height: 60px;
        text-align: center;
        line-height: 60px;

    }
}
